import GaugeChart from "react-gauge-chart";
import {
	OneBoxRectangle,
	OneBoxSquare,
	RemarkAndScore,
	TwoBarChart,
	PieChart,
} from "./OneBoxContext";
import OpeningList from "./OpeningsList";
import { useState, useEffect } from "react";
import React from "react";
import CVRadarChart from "./CVRadarChart";
import { WorkingExperienceGaugeChart } from "./WorkingExperienceGaugeChart";
import { PrintableView } from "./Subitem";
import { SkillsMatched } from "./OneBoxContext";
import { CandidatesCommentDrawer } from "../Candidates/CandidatesCommentDrawer";

function RHSContent(props) {
	const [currentData, setcurrentData] = useState(props.data.currentOpenings[0]);
	const [isSelected, setisSelected] = useState(0);
	const [barOnenumberOfTotal, setbarOnenumberOfTotal] = useState(
		Math.floor(
			(currentData.workingExperience.totalyears /
				currentData.workingExperience.requestyears) *
				100
		)
	);
	const [barTwonumberOfTotal, setbarTwonumberOfTotal] = useState(
		Math.floor(
			(currentData.workingExperience.relevantyears /
				currentData.workingExperience.requestyears) *
				100
		)
	);
	const [skillSetScoreMarks, setskillSetScoreMarks] = useState(0);
	const [skillSetScore, setskillSetScore] = useState(
		"conic-gradient(rgb(145, 142, 142) 0deg, rgb(145, 142, 142) 360deg ,rgb(75, 43, 105) 360deg )"
	);

	const skillsMatched = currentData.skillsFits.filter(
		(skill) => skill.isLearned === true
	).length;

	const totalSkillsRequired = currentData.skillsFits.length;

	const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState(false);

	useEffect(() => {
		setbarOnenumberOfTotal(
			Math.floor(
				(currentData.workingExperience.totalyears /
					currentData.workingExperience.requestyears) *
					100
			)
		);
		setbarTwonumberOfTotal(
			Math.floor(
				(currentData.workingExperience.relevantyears /
					currentData.workingExperience.requestyears) *
					100
			)
		);
		Barnumber(barOnenumberOfTotal);
		Barnumber(barTwonumberOfTotal);
		skillSet(currentData.skillsFits);
		setskillSetScore(skillSetcolor());
	});

	useEffect(() => {
		setcurrentData(props.data.currentOpenings[0]);
		console.log("RHSContent==========================");
		console.log("RHSContent");
		console.log("props", props);
		console.log("props.data", props.data);
		console.log("props.data.currentOpenings", props.data.currentOpenings);
		console.log("RHSContent==========================");
	}, [props]);

	console.log("isCommentDrawerOpen", isCommentDrawerOpen);

	function skillSet(x) {
		if (x !== undefined) {
			let sum = x.length;
			let total = 0;
			let selectedItem = x.map((x) =>
				x.isLearned === true ? (total += 1) : ""
			);
			selectedItem = Math.floor((total / sum) * 100);
			return setskillSetScoreMarks(selectedItem);
		}
	}

	function skillSetcolor() {
		let finalbnumber = Math.floor(360 - 3.6 * skillSetScoreMarks);
		const newskillSetScore =
			"conic-gradient(rgb(145, 142, 142) 0deg, rgb(145, 142, 142) " +
			finalbnumber +
			"deg ,rgb(75, 43, 105) " +
			finalbnumber +
			"deg )";
		return newskillSetScore;
	}

	function handleSelect(index) {
		setcurrentData(props.data.currentOpenings[index]);
		setisSelected(index);
	}
	function Hml(x) {
		if (x === "Medium") {
			return "text-color-orange";
		} else if (x === "High") {
			return "text-color-green";
		} else if (x === "Low") {
			return "text-color-red";
		}
	}
	function FulfillAndUnder(x) {
		if (x === "Fulfill") {
			return "text-color-green";
		} else if (x === "Not fulfill") {
			return "text-color-red";
		}
	}
	function RectangleInOut(x) {
		if (x === "Interview") {
			return "bg-color-green";
		} else if (x === "On Hold") {
			return "bg-color-orange";
		} else if (x === "Reject") {
			return "bg-color-red";
		}
	}
	function Arraymapping(x) {
		if (x !== undefined) {
			var Array = x.map((x, index) => {
				const contentWithLineBreaks =
					typeof x.content === "string"
						? x.content.replace(/\\n+/g, "<br>")
						: x.content;
				return (
					<div className="" key={index}>
						<div className="">
							<b>{x.title + ":"}</b>
						</div>
						<div
							className=""
							style={{ whiteSpace: "pre-wrap" }}
							dangerouslySetInnerHTML={{ __html: contentWithLineBreaks }}
						/>
						<br></br>
					</div>
				);
			});
			return Array;
		}
	}
	function Barnumber(x) {
		if (x > 100) {
			return "100%";
		} else {
			return x + "%";
		}
	}
	return (
		<div className="RHS-grid-container-out">
			<div className="RHS-grid-container">
				<div className="RHS-grid-item RHS-grid-item1">
					<OneBoxRectangle
						style="bg-color-jardines"
						titleKey="Selected Opening for Candidate"
						titlValue={currentData.currentPost}
					/>
				</div>
				<div className="RHS-grid-item RHS-grid-item2">
					<OneBoxRectangle
						style={RectangleInOut(currentData.suggestedAction)}
						titleKey="Suggested Action"
						titlValue={currentData.suggestedAction}
					/>
				</div>

				<div className="RHS-grid-item RHS-grid-item3">
					<WorkingExperienceGaugeChart
						titleKey="Working Experience"
						workingExperience={currentData.workingExperience}
					/>
				</div>
				<div className="RHS-grid-item RHS-grid-item4">
					<OpeningList
						keyname="Matched Openings"
						item="Score"
						currentOpenings={
							props.data.currentOpenings.slice(0, 3)
							// .filter((i) => i.suggestedAction !== "Reject")
						}
						isSelected={isSelected}
						handleSelect={handleSelect}
					/>
				</div>
				<div className="RHS-grid-item RHS-grid-item5">
					<OneBoxSquare
						squareStyle={Hml(currentData.jobStability)}
						squaretitleStyle="one-box-square-Pink"
						titleKey="Job Stability"
						titleValue={currentData.jobStability}
					/>
				</div>
				<div className="RHS-grid-item RHS-grid-item6">
					<OneBoxSquare
						squareStyle={FulfillAndUnder(currentData.education)}
						squaretitleStyle="one-box-square-Purple"
						titleKey="Education"
						titleValue={currentData.education}
					/>
				</div>
				<div className="RHS-grid-item RHS-grid-item9">
					<div className="fullsize">
						<SkillsMatched
							titleKey={`Skills Matched: ${skillsMatched}/${totalSkillsRequired}`}
							data={currentData.skillsFits}
						/>
					</div>
				</div>
				{/* <div className="RHS-grid-item printable-skills-matched">
          <PrintableView
            leftTitle="Requirements"
            rightTitle="Candidate"
            data={currentData.skillsFits}
          />
        </div> */}
				<div className="RHS-grid-item RHS-grid-item10">
					<div className="RadarChart-Base">
						<div className="RadarChart-Title padding-topleft-corner">
							<div className="RadarChart-Title-Text">Candidate Attributes</div>
						</div>
						<CVRadarChart data={currentData.candidateScoreinDiffSkills} />
					</div>
				</div>
				<div className=" RHS-grid-item11">
					<div className="RHS-grid-item remarkAndScore">
						<RemarkAndScore
							titleKey={"Score: " + currentData.score + " /100"}
							titleValue={Arraymapping(currentData.scoreSummary)}
						/>
						<RemarkAndScore
							titleKey={"Remarks"}
							titleValue={Arraymapping(currentData.remarks)}
							isCommentDrawerOpen={isCommentDrawerOpen}
							setIsCommentDrawerOpen={setIsCommentDrawerOpen}
						/>
					</div>
				</div>

				<CandidatesCommentDrawer
					candidateAnalysis={props.data}
					selectedIntID={props.data.candidateId}
					isCommentDrawerOpen={isCommentDrawerOpen}
					setIsCommentDrawerOpen={setIsCommentDrawerOpen}
				/>
			</div>
		</div>
	);
}

export default RHSContent;
