import React from "react";
import {
	CheckmarkCircleFilled,
	DismissCircleFilled,
} from "@fluentui/react-icons";
import { Divider } from "@fluentui/react-components";
import { Stack, StackItem } from "@fluentui/react/lib/Stack";

export function View(props) {
	console.log("props.data View Subitem", props.data);
	if (props.data !== undefined) {
		var Skills = props.data.map((items, index) => (
			<div className="view-item" key={index}>
				<div className="position">
					<b>{items.skillName}</b>
				</div>
			</div>
		));
		var SkillsLearned = props.data.map((items, index) => (
			<div className="view-item align" key={index}>
				<div
					className={items.isLearned === true ? "true left" : "false left"}
				></div>{" "}
				<div className="position">
					<b>{items.skillName}</b>
				</div>
			</div>
		));
	}

	return (
		<>
			<div className="view-box-base">
				<div className="view-box-inside">
					<div className="view-box-inside-content">
						<div className="view-box-inside-content-title">
							<div className="view-box-inside-content-left-title">
								<b>{props.leftTitle}</b>
							</div>
							<div className="view-box-inside-content-middle-title">
								<div className="line"></div>
							</div>
							<div className="view-box-inside-content-right-title">
								<b>{props.rightTitle}</b>
							</div>
						</div>
						<div className="view-box-inside-content-value">
							<div className="view-box-inside-content-left-value">{Skills}</div>
							<div className="view-box-inside-content-middle-value">
								<div className="line-purple"></div>
							</div>
							<div className="view-box-inside-content-right-value">
								<div className="view-box-inside-content-right-value-fix">
									{SkillsLearned}
								</div>
							</div>
						</div>
					</div>
					<div className="view-box-exit" onClick={props.close}>
						<div className="view-box-exit-text">
							<b>x</b>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export function ViewCV(props) {
	if (props.viewimage !== undefined) {
		var viewimages = (
			<iframe
				className="preview-pdf"
				src={props.preview}
				title="CV Preview"
			></iframe>
		);
	}

	return (
		<>
			<div className="view-image-base onecolumn column-center">
				<div className="view-image-exit" onClick={props.close}>
					<div className="view-image-exit-text">
						<b>X</b>
					</div>
				</div>
				<div className="view-image-inside">{viewimages}</div>
			</div>
		</>
	);
}

export function PrintableView(props) {
	if (props.data !== undefined) {
		var Skills = props.data.map((items, index) => (
			<div className="printable-view-item" key={index}>
				<div className="position">
					<p>{items.skillName}</p>
				</div>
			</div>
		));
		var SkillsLearned = props.data.map((items, index) => (
			<div
				className="printable-view-item align"
				style={{ display: "flex", alignItems: "baseline", gap: "5px" }}
				key={index}
			>
				{items.isLearned === true ? (
					<div>
						<CheckmarkCircleFilled style={{ color: "#008656" }} />
					</div>
				) : (
					<div>
						<DismissCircleFilled style={{ color: "#BE0000" }} />
					</div>
				)}
				<div className="printable-position">
					<p>{items.skillName}</p>
				</div>
			</div>
		));
	}

	return (
		<>
			<div style={{ display: "flex" }}>
				<div className="printable-view-box-inside-content-title">
					<div className="printable-view-box-inside-content-left-title">
						<b>{props.leftTitle}</b>
					</div>
					<div>
						<Divider />
					</div>
					<Stack>
						<StackItem>{Skills}</StackItem>
					</Stack>
				</div>
				<div
					style={{ display: "flex", alignItems: "center", padding: "0 20px" }}
				>
					<Divider vertical style={{ height: "80%", flexGrow: 0.5 }} />
				</div>
				<div className="printable-view-box-inside-content-title">
					<div className="printable-view-box-inside-content-right-title">
						<b>{props.rightTitle}</b>
					</div>
					<div>
						<Divider />
					</div>
					<Stack>
						<StackItem>{SkillsLearned}</StackItem>
					</Stack>
				</div>
			</div>
		</>
	);
}
