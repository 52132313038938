import React, { useState, useEffect, useMemo } from "react";

import {
	TableColumnDefinition,
	createTableColumn,
	TableCellLayout,
	PresenceBadgeStatus,
	Avatar,
	useScrollbarWidth,
	useFluent,
	TableCellActions,
	Menu,
	MenuTrigger,
	MenuItem,
	MenuList,
	MenuPopover,
	Button,
	Dialog,
	DialogTrigger,
	DialogSurface,
	DialogTitle,
	DialogBody,
	DialogContent,
	Link,
	DataGridProps,
	makeStyles,
	Text,
	Caption1,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import {
	DataGridBody,
	DataGrid,
	DataGridRow,
	DataGridHeader,
	DataGridCell,
	DataGridHeaderCell,
	RowRenderer,
} from "@fluentui-contrib/react-data-grid-react-window";
import LoadingSpinner from "../../components/LoadingSpinner";
import "../../styles/JobOpenings.css";

import { JobOpeningsItem, ReviewItem } from "../../models/JobOpenings";

const useStyles = makeStyles({
	field: {
		fontWeight: 600,
		padding: "0.5rem 0",
	},
});

const columnSizingOptions = {
	title: {
		defaultWidth: 300,
		minWidth: 200,
		idealWidth: 300,
	},
	review: {
		defaultWidth: 270,
		minWidth: 230,
		idealWidth: 270,
	},
	topMatchName: {
		defaultWidth: 230,
		minWidth: 200,
		idealWidth: 230,
	},
	topMatchScore: {
		defaultWidth: 80,
		maxWidth: 80,
		minWidth: 50,
		idealWidth: 80,
	},
	marketSalary: {
		defaultWidth: 170,
		minWidth: 130,
		maxWidth: 170,
		idealWidth: 170,
	},
	minInternalSalary: {
		defaultWidth: 170,
		minWidth: 130,
		maxWidth: 170,
		idealWidth: 170,
	},
	maxInternalSalary: {
		defaultWidth: 170,
		minWidth: 130,
		maxWidth: 170,
		idealWidth: 170,
	},
	postDate: {
		defaultWidth: 170,
		maxWidth: 170,
		idealWidth: 170,
	},
	status: {
		defaultWidth: 180,
		minWidth: 150,
		idealWidth: 180,
	},
};

export const EmployeeTable = ({ ...props }) => {
	const {
		onEdit,
		onSelectionChange,
		sortedData,
		itemSize,
		tableHeight,
		originalRecords,
		filteredRecords,
		setOriginalRecords,
		setFilteredRecords,
		searchStatus,
		// filterInputForm,
	} = props;
	const styles = useStyles();
	// const [sortedData, setSortedData] = useState<IJob[]>([]);
	const [selectedReview, setSelectedReview] = useState<ReviewItem[]>([]);
	const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
	const [reviewOpeningId, setReviewOpeningId] = useState<any>(null);

	const { targetDocument } = useFluent();
	const scrollbarWidth = useScrollbarWidth({ targetDocument });

	const handleMatchedRowSelect = (e: any, reviewItem: any, id: any) => {
		e.stopPropagation();
		console.log("handleMatchedCandidateSelect", reviewItem);
		setReviewOpeningId(id);
		setSelectedReview(reviewItem);
	};

	const handleCandidateClick = (e: any, id: number | undefined) => {
		e.stopPropagation();
		window.open(`candidates/dashboard/${id}`, "_blank");
	};

	const filteredRecommendation = (reviews: any) => {
		const filteredReviews = reviews.filter(
			(item: any) => item.recommendation && item.recommendation !== "Reject"
			//&& item.score >= filterInputForm.score
		);
		return filteredReviews;
	};
	const [reviewSortState, setReviewSortState] = useState<
		Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]
	>({
		sortColumn: "score",
		sortDirection: "descending",
	});
	const onReviewSortChange: DataGridProps["onSortChange"] = (
		e,
		nextSortState
	) => {
		setReviewSortState(nextSortState);
	};

	const reviewColumns: TableColumnDefinition<ReviewItem>[] = [
		createTableColumn<ReviewItem>({
			columnId: "name",
			compare: (a, b) => {
				return (a.name || "").localeCompare(b.name || "");
			},
			renderHeaderCell: () => {
				return "Name";
			},
			renderCell: (item) => {
				return (
					<TableCellLayout
						style={{ cursor: "pointer" }}
						onClick={(e) => {
							handleCandidateClick(e, item.candidateId);
							console.log("item", item);
						}}
					>
						<Link>{item.name}</Link>
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<ReviewItem>({
			columnId: "recommendation",
			compare: (a, b) => {
				return (a.recommendation || "").localeCompare(b.recommendation || "");
			},
			renderHeaderCell: () => {
				return "Recommendation";
			},
			renderCell: (item) => {
				return <TableCellLayout>{item.recommendation}</TableCellLayout>;
			},
		}),
		createTableColumn<ReviewItem>({
			columnId: "score",
			compare: (a, b) => {
				return Number(a.score) - Number(b.score);
			},
			renderHeaderCell: () => {
				return "Score";
			},

			renderCell: (item) => {
				return item.score;
			},
		}),
	];

	const [sortState, setSortState] = useState<
		Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]
	>({
		sortColumn: "postDate",
		sortDirection: "descending",
	});
	const onSortChange: DataGridProps["onSortChange"] = (e, nextSortState) => {
		setSortState(nextSortState);
	};

	// console.log("sortedData", sortedData);
	// console.log("originalRecords", originalRecords);
	// console.log("filteredRecords", filteredRecords);

	const columns: TableColumnDefinition<JobOpeningsItem>[] = [
		createTableColumn({
			columnId: "title",
			compare: (a, b) => {
				return (a.title ?? "").localeCompare(b.title ?? "");
			},
			renderHeaderCell: () => {
				return "Job Title";
			},
			renderCell: (item) => {
				return (
					<TableCellLayout
						truncate
						// media={item.title.icon}
					>
						<Link
							style={{ cursor: "pointer", fontWeight: "600" }}
							onClick={(e) => {
								e.stopPropagation();
								console.log("clicked job title", item);
								onEdit(item.id);
							}}
						>
							{item.title}
						</Link>
					</TableCellLayout>
					// <OpeningModal item={item}/>
				);
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "review",
			compare: (a, b) => {
				return a.review.length - b.review.length;
			},
			renderHeaderCell: () => {
				return "Review matched candidate(s)";
			},
			renderCell: (item) => {
				const renderMatchedRow: RowRenderer<ReviewItem> = (
					{ item, rowId },
					style
				) => (
					<DataGridRow<ReviewItem> key={rowId} style={style}>
						{({ renderCell }) => (
							<DataGridCell>{renderCell(item)}</DataGridCell>
						)}
					</DataGridRow>
				);

				return !item || filteredRecommendation(item.review).length === 0 ? (
					<TableCellLayout truncate>
						There are currently no matched candidates.
					</TableCellLayout>
				) : (
					<Dialog
						open={item.id === reviewOpeningId}
						onOpenChange={(event, data) => {
							event.stopPropagation();
							if (data.open) {
								setReviewOpeningId(item.id);
							} else {
								setReviewOpeningId(null);
							}
						}}
					>
						<DialogTrigger disableButtonEnhancement>
							<TableCellLayout
								truncate
								onClick={(e) => handleMatchedRowSelect(e, item.review, item.id)}
								style={{ cursor: "pointer" }}
							>
								<Link style={{ textDecoration: "underline" }}>
									Click to see a total of{" "}
									{filteredRecommendation(item.review).length} candidate(s)
								</Link>
							</TableCellLayout>
						</DialogTrigger>
						<DialogSurface>
							<DialogBody onClick={(e) => e.stopPropagation()}>
								<DialogTitle
									action={
										<DialogTrigger action="close">
											<Button
												appearance="subtle"
												aria-label="close"
												icon={<Dismiss24Regular />}
												onClick={() => setReviewOpeningId(null)}
											/>
										</DialogTrigger>
									}
								>
									{item.title}
								</DialogTitle>
								<DialogContent>
									<DataGrid
										// selectionMode="multiselect"
										items={filteredRecommendation(item.review)}
										columns={reviewColumns}
										// focusMode="composite"
										sortable
										sortState={reviewSortState}
										onSortChange={onReviewSortChange}
									>
										<DataGridHeader>
											<DataGridRow
												style={{ height: "3rem" }}
												// selectionCell={{
												// 	checkboxIndicator: {
												// 		"aria-label": "Select all rows",
												// 	},
												// }}
											>
												{({ renderHeaderCell }) => (
													<DataGridHeaderCell className={styles.field}>
														{renderHeaderCell()}
													</DataGridHeaderCell>
												)}
											</DataGridRow>
										</DataGridHeader>
										<DataGridBody itemSize={50} height={400}>
											{renderMatchedRow}
										</DataGridBody>
									</DataGrid>
								</DialogContent>
							</DialogBody>
						</DialogSurface>
					</Dialog>
				);
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "topMatchName",
			compare: (a, b) => {
				return (a.marketSalary ?? "").localeCompare(b.marketSalary || "");
			},
			renderHeaderCell: () => {
				return "Top matched candidate(s)";
			},

			renderCell: (item) => {
				// console.log("top matched", item);
				const topMatch = (reviews: any, column: string) => {
					const maxScore = Math.max(
						...reviews.map((candidate: ReviewItem) => candidate.score)
					);
					const topMatchNames = reviews
						.filter((candidate: ReviewItem) => candidate.score === maxScore)
						.map((i: ReviewItem) => i.name);
					if (column === "name") {
						// console.log(topMatchNames);
						return topMatchNames;
					}
					if (column === "score") {
						return maxScore;
					}
				};
				return (
					<>
						{item.review.length === 0 ||
						filteredRecommendation(item.review).length === 0 ? (
							<div className="table-na">N/A</div>
						) : (
							<div
								className="top-matched-candidate"
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
									paddingRight: "16px",
									overflow: "hidden",
								}}
							>
								<span style={{ fontWeight: "600", whiteSpace: "normal" }}>
									<ul
										style={{
											display: "flex",
											flexDirection: "column",
											margin: "0",
											padding: "0",
											overflow: "hidden",
										}}
									>
										{topMatch(item.review, "name")
											.slice(0, 3)
											.map((i: any, index: number) => {
												return <li key={`matched-${index}`}>{i}</li>;
											})}
										{topMatch(item.review, "name").length > 3 && (
											<Link
												style={{
													fontWeight: "600",
													marginTop: "6px",
												}}
												onClick={(e) => {
													e.stopPropagation();
													handleMatchedRowSelect(e, item.review, item.id);
												}}
											>
												+ {topMatch(item.review, "name").length - 3} more...
											</Link>
										)}
									</ul>
								</span>
							</div>
						)}
					</>
				);
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "topMatchScore",
			compare: (a, b) => {
				return (a.marketSalary ?? "").localeCompare(b.marketSalary || "");
			},
			renderHeaderCell: () => {
				return "Score";
			},

			renderCell: (item) => {
				const topMatch = (reviews: any, column: string) => {
					const maxScore = Math.max(
						...reviews.map((candidate: ReviewItem) => candidate.score)
					);
					const topMatchNames = reviews
						.filter((candidate: ReviewItem) => candidate.score === maxScore)
						.map((i: ReviewItem) => i.score);
					if (column === "name") {
						return topMatchNames;
					}
					if (column === "score") {
						return maxScore;
					}
				};
				return (
					<>
						{item.review.length === 0 ||
						filteredRecommendation(item.review).length === 0 ? (
							<div style={{ display: "flex", justifyContent: "center" }}>
								<p>
									<i>N/A</i>
								</p>
							</div>
						) : (
							<div
								className="top-matched-candidate-score"
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
									paddingRight: "16px",
								}}
							>
								<span style={{ fontWeight: "600" }}>
									{topMatch(item.review, "score")}
								</span>
							</div>
						)}
					</>
				);
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "marketSalary",
			compare: (a, b) => {
				return (a.marketSalary ?? "").localeCompare(b.marketSalary || "");
			},
			renderHeaderCell: () => {
				return "Market Salary";
			},

			renderCell: (item) => {
				return (
					<TableCellLayout>
						{item.marketSalary ? (
							new Intl.NumberFormat("en-HK", {
								style: "currency",
								currency: item.title.toLowerCase().includes("indonesia")
									? "IDR"
									: "HKD",
								currencyDisplay: "symbol",
							})
								.format(Number(item.marketSalary))
								.replace("HK", "HKD ")
						) : (
							<i>
								<Caption1>Not Provided</Caption1>
							</i>
						)}
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "minInternalSalary",
			compare: (a, b) => {
				return (a.minInternalSalary ?? "").localeCompare(
					b.minInternalSalary || ""
				);
			},
			renderHeaderCell: () => {
				return "Min Internal Salary";
			},

			renderCell: (item) => {
				return (
					<TableCellLayout>
						{item.minInternalSalary ? (
							new Intl.NumberFormat("en-HK", {
								style: "currency",
								currency: item.title.toLowerCase().includes("indonesia")
									? "IDR"
									: "HKD",
								currencyDisplay: "symbol",
							})
								.format(Number(item.minInternalSalary))
								.replace("HK", "HKD ")
						) : (
							<i>
								<Caption1>Not Provided</Caption1>
							</i>
						)}
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "maxInternalSalary",
			compare: (a, b) => {
				return (a.maxInternalSalary ?? "").localeCompare(
					b.maxInternalSalary || ""
				);
			},
			renderHeaderCell: () => {
				return "Max Internal Salary";
			},

			renderCell: (item) => {
				return (
					<TableCellLayout>
						{item.maxInternalSalary ? (
							new Intl.NumberFormat("en-HK", {
								style: "currency",
								currency: item.title.toLowerCase().includes("indonesia")
									? "IDR"
									: "HKD",
								currencyDisplay: "symbol",
							})
								.format(Number(item.maxInternalSalary))
								.replace("HK", "HKD ")
						) : (
							<i>
								<Caption1>Not Provided</Caption1>
							</i>
						)}
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "postDate",
			compare: (a, b) => {
				return (a.postDate ?? "").localeCompare(b.postDate ?? "");
			},
			renderHeaderCell: () => {
				return "Post Date";
			},

			renderCell: (item) => {
				return item.postDate;
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "status",
			// compare: (a, b) => {
			// 	return (a.status?? "").localeCompare(b.status?? "");
			// },
			renderHeaderCell: () => {
				return "Status";
			},

			renderCell: (item) => {
				let style = {
					fontSize: "14px",
					borderRadius: "4px",
					padding: "4px 8px",
					margin: "0 3px",
					border: "none",
					lineHeight: "1.5",
					background: "",
					color: "",
					fontWeight: 600,
				};

				if (item.status === "Open") {
					style.background = "#81c784";
					style.color = "#3D5E3E";
				} else if (item.status === "Closed") {
					style.background = "#adb5bd";
					style.color = "#45484c";
				} else if (item.status === "Under Review") {
					style.background = "#ffc107";
					style.color = "#664d03";
				}

				return <span style={style}>{item.status}</span>;
			},
		}),
	];

	const renderOpeningsRow: RowRenderer<JobOpeningsItem> = (
		{ item, rowId },
		style
	) => (
		<DataGridRow<JobOpeningsItem> key={rowId} style={style}>
			{({ renderCell }) => (
				<DataGridCell className="data-grid-cell">
					{renderCell(item)}
				</DataGridCell>
			)}
		</DataGridRow>
	);

	return (
		<DataGrid
			size="small"
			items={filteredRecords ? filteredRecords.result : []}
			columns={columns}
			focusMode="cell"
			sortable
			sortState={sortState}
			onSortChange={onSortChange}
			selectionMode="multiselect"
			onSelectionChange={(e, data) => {
				onSelectionChange(data);
				// console.log("onSelectionChange", data);
			}}
			getRowId={(item) => item.id}
			selectionAppearance="neutral"
			columnSizingOptions={columnSizingOptions}
			resizableColumns
			style={{
				backgroundColor: "#FFFFFF",
				border: "1px solid #E0E0E0",
				borderRadius: "4px",
				// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.10)",
			}}
		>
			<DataGridHeader>
				<DataGridRow>
					{({ renderHeaderCell }) => (
						<DataGridHeaderCell className={styles.field}>
							{renderHeaderCell()}
						</DataGridHeaderCell>
					)}
				</DataGridRow>
			</DataGridHeader>
			{filteredRecords?.result.length === 0 ? (
				<div
					style={{
						padding: "24px 0",
						display: "flex",
						justifyContent: "center",
						height: `${tableHeight}px`,
					}}
				>
					<Text italic>
						No results found. Please try adjusting your filters or check back
						later.
					</Text>
				</div>
			) : searchStatus ? (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						height: `${tableHeight}px`,
						flexGrow: 1,
						backgroundColor: "#FFFFFF",
						padding: "24px",
						overflowY: "auto",
						position: "relative",
					}}
				>
					<LoadingSpinner />
				</div>
			) : (
				<DataGridBody<JobOpeningsItem> itemSize={itemSize} height={tableHeight}>
					{renderOpeningsRow}
				</DataGridBody>
			)}
		</DataGrid>
	);
};
